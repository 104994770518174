// Libraries
import React, { useContext, useRef, createRef, useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, Dimensions, TouchableOpacity, TouchableWithoutFeedback, FlatList, ScrollView} from 'react-native';
import { AntDesign, Entypo, FontAwesome5} from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Functions
import { get_script } from '../functions/adviezen';

// Components
import { GlobalDispatcher } from '../context/globalState';
import { P, H1, H2, H3, H4, Link } from '../components/text';
import { PictoBox } from '../components/images';
import { Button } from './button';

import IconSetter from './iconSetter';
import { isMetric } from 'expo-localization';


const bepaalKolommen = ( stap_kolommen, aantal_antwoorden, tablet, mobile ) => {

  if (aantal_antwoorden == 1) return 1;
  if ( mobile ) return 1;
  if ( tablet ) {
    if ( stap_kolommen >= 2 ) return 2;
    return 1;
  }

  return stap_kolommen;
}



// Default function
export default function RightContainer( props ) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo_small.png');

  const inputsContent = props.inputsContent || [{ size: 25, content: <P>input 1</P> }, { size: 25, content: <P>input 2</P> }, { size: 25, content: <P>input 3</P> }, { size: 13, content: <P>input 4</P> }, { size: 12, content: <P>input 5</P> }];
  const buttonContentPrev = props.buttonContentPrev || "Vorige";
  const buttonContentNext = props.buttonContentNext || "Volgende";
  const onPressPrev = props.onPressPrev;// || function () { alert('maak onPress aan op buttton') };
  const onPressNext = props.onPressNext;
  const onHomePress = props.onHomePress || console.log('Reee');
  const icon = props.icon;
  const focus = props.focus;
  const stapData = props.stapData;
  const inputAantal = props.inputAantal;
  const inputDataFilled = props.inputDataFilled;
  const mobile = props.mobile;
  const tablet = props.tablet;
  const statusData = props.statusData;
  const config = props.config;
  const clickHandler = props.clickHandler;
  const role = props.role || '';
  const colomAantal = bepaalKolommen( stapData.sta_kolommen, stapData.antwoorden.length, tablet, mobile );
  const colomPercentage = Math.round(100/colomAantal);
  let value = null;
  let cols = null;
  let rows = null;
  if(config){
    cols = config.cols;
    rows = config.rows;
  }
  const { styles } = useStyles();
  
  //console.log(statusData);
  //console.log(inputsContent);
  //console.log(config);
  //console.log(mobile);
  //console.log(icon);
  //console.log(inputDataFilled);


  //Knop instellingen
  let disableButton = false; //true; Uitgezet Ron 15-1-2024
  let colorButton = {backgroundColor: Colors[20]};
  let colorArrow = Colors[9];
  let buttonTextColor = Colors[9];
  let borderColor = {borderColor: 'rgba(100, 100, 100, 0.0)'};
  let hoverButton = [styler.hover, { borderBottomLeftRadius: global.settings.button_border_radius,
    borderBottomRightRadius: global.settings.button_border_radius, borderWidth: mobile ? 0 : 1, borderColor: Colors[9]}];
  if(inputDataFilled >= inputAantal){
    disableButton = false;
    colorButton = {backgroundColor: Colors[7]};
    colorArrow = Colors[10];
    buttonTextColor = Colors[16];
    borderColor = { borderColor: Colors[9], borderWidth: 1};
    hoverButton = [styler.allowHover, { borderBottomLeftRadius: global.settings.button_border_radius,
    borderBottomRightRadius: global.settings.button_border_radius, borderWidth: mobile ? 0 : 1, borderColor: Colors[9]}];
  }
  
  const inputLength = inputsContent.length;
  const ref = createRef(null);
  const isHovered = useHover(ref);


  const refPrev = createRef(null);
  const isHoveredPrev = useHover(refPrev);

  const toelichting2 = get_script(stapData.sta_toelichting2, true);

  let tabletLandscape = false;
  if(Dimensions.get('screen').width  >= 1024 && Dimensions.get('screen').height <= 820){
    tabletLandscape = true;
  }
  return (
    <View style={{ flexDirection: 'column', flex: 65, marginBottom: tabletLandscape ? 40 : 60 }}>
      <ScrollView style={mobile ? styler.scrollViewMobile : [styler.scrollView, {  height: inputsContent.length > 12 ? '80%' : '100%'}]} showsVerticalScrollIndicator={false} >
        <View  
          style={[mobile ? styler.rowViewMobile : (inputLength == 1 ? styler.widthView : styler.rowView), {marginBottom: icon ? 40 : 0}]}
          accessibilityRole={role}
        >
          { config ?
            rows.map((row, index) => {
              if(statusData[row.id] != "invisible"){
                return (
                  <View style={ mobile == true ? styler.objectViewMobile : styler.objectView } key={index}>
                    {
                      row.objects.map((object, index) => {
                        //Extra veiligheidscheck, zou niet nodig moeten zijn
                        if(cols == null){
                          cols = 2
                        }

                        if(mobile == true){
                          cols = 1;
                        }

                        if(tablet == true){
                          if(cols  >  2){
                           cols = 2;
                          }
                        }

                        //Gebruik de width uit objects en de cols om de breedte van de input te bepalen
                        const width_divider = object.width / cols;
                        let input_width = Math.round(100 * width_divider) + "%";

                        //console.log(object);
                        return (
                          inputsContent.map((item, index) => {
                            if(item.show == false){
                              return false;
                            }
                            if(item.name == object.name){
                              //console.log("heeft property " + object.name);

                              let input_value = item.input.props.value ?? item.input.props.setText ?? item.input.props.input;
                              if(input_value == 0){
                                input_value = '';
                              }

                              return(
                                <View style={mobile == true ? { width: input_width } : { width: input_width, marginBottom: 10, marginRight: 10 }} key={index}>
                                  <View style={ mobile == true ? null : { minHeight: 19 }}>{item.titel}</View>
                                  {item.input}
                                </View>
                              )
                            }
                          })
                        )
                      })
                    }
                  </View>
                )
              }
            })
          :
            inputsContent.map((item, index) => {
              let input_value = item.input.props.value ?? item.input.props.setText ?? item.input.props.input;
              if(input_value == 0){
                input_value = '';
              }
              const input_type = item.input_type;

              return(
                <View 
                  style={ mobile == true ?  styler.inputViewMobile : { width : colomPercentage + '%', marginBottom: 15 } }
                  key={index}>
                  {item.titel}
                  {item.input}
                </View>
              )
            })
          }
        </View>
        { toelichting2 == '' ? null :
        <View accessible={true} style={{ justifyContent: 'center', marginHorizontal: 35, zIndex: -1 }}>
          <div onClick={clickHandler} style={{ marginTop: 20, paddingHorizontal: 20, alignSelf: mobile ? 'center' : 'flex-start', fontFamily: "Regular", fontSize: 14, lineHeight: '120%', color: Colors[9] }} dangerouslySetInnerHTML={{ __html: toelichting2 }} />
        </View>
        }
      </ScrollView> 

      <View style={ mobile ? {position: 'fixed', bottom: 0, zIndex: 999999999, backgroundColor: Colors[0], borderTopWidth: 1, borderTopColor:'#DEDEDE', width: '100%'} : styles('desktopButtons')}>
        <View style={mobile == true ? styler.buttonAreaMobile : [styler.buttonArea]}>
          { mobile ?<View style={{ width: '90%', flexDirection: 'row', right: -10 }}>
            <View style={[styler.buttonPrev, styler.allowHover, {backgroundColor: Colors[20], borderRadius: global.settings.button_border_radius, borderColor: global.settings.border_always_visible  !== 0 ? Colors[9] : Colors[0], borderWidth: 1, transition: '0.3s'}]} ref={refPrev}>
              <TouchableWithoutFeedback accessibilityRole='button' onPress={onPressPrev}>
                <View style={{flexDirection: 'row', alignSelf: 'center', paddingLeft: 20, paddingRight: 38, paddingVertical: 13, borderRadius: global.settings.button_border_radius }}>
                  <View style={{ top: tablet ? 2 : 1, paddingRight: 25 }}>
                    <FontAwesome5 name="chevron-left" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)' }} size={ tablet ? 15 : 17 } color="black" />
                  </View>
                  <View>
                    <H4 fontWeight='700' fontSize={18}>{global.settings.text_to_upper_case == 0 ? buttonContentPrev : buttonContentPrev.toUpperCase()}</H4>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
            <View style={[[styler.buttonNext, {borderRadius: global.settings.button_border_radius, backgroundColor: Colors[20], borderWidth: 1, borderColor: global.settings.border_always_visible  !== 0 ? Colors[9] : Colors[0], transition: '0.3s'}], colorButton]} ref={ref}>
              <TouchableWithoutFeedback accessibilityRole='button' onPress={onPressNext} disabled={disableButton}>
                <View style={{flexDirection: 'row', alignSelf: 'center', paddingLeft: 35, paddingRight: 30, paddingVertical: 13, borderRadius: global.settings.button_border_radius }}>
                  <H4 fontWeight='700' fontSize={18} color={buttonTextColor}>{global.settings.text_to_upper_case == 0 ? buttonContentNext : buttonContentNext.toUpperCase()}</H4>
                  <View style={{ top: tablet ? 2 : 1, paddingLeft: 12 }}>
                    <FontAwesome5 name="chevron-right" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)' }} size={ tablet ? 15 : 17} color={colorArrow} />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
          :
          <View style={{ flexDirection: 'row'}}>
            <View style={[styler.buttonPrev, styler.allowHover, {backgroundColor: Colors[20], borderRadius: global.settings.button_border_radius, borderColor: isHoveredPrev ? Colors[9] : global.settings.border_always_visible  !== 0 ? Colors[9] : Colors[0], borderWidth: 1, transition: '0.3s'}]} ref={refPrev}>
              <TouchableWithoutFeedback accessibilityRole='button' onPress={onPressPrev}>
                <View style={{flexDirection: 'row', alignSelf: 'center', paddingLeft: 20, paddingRight: 38, paddingVertical: 13, borderRadius: global.settings.button_border_radius }}>
                  <View style={{ top: tablet ? 2 : 1, paddingRight: 25 }}>
                    <FontAwesome5 name="chevron-left" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)' }} size={ tablet ? 15 : 17 } color="black" />
                  </View>
                  <View>
                    <H4 fontWeight='700' fontSize={18}>{global.settings.text_to_upper_case == 0 ? buttonContentPrev : buttonContentPrev.toUpperCase()}</H4>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
            <View style={[[styler.buttonNext, {borderWidth: 1, borderRadius: global.settings.button_border_radius, borderColor: isHovered ? Colors[9] : global.settings.border_always_visible  !== 0 ? Colors[9] : Colors[0], transition: '0.3s'}], isHovered && hoverButton,  isHovered ? { backgroundColor: Colors[7]} : colorButton]} ref={ref}>
              <TouchableWithoutFeedback accessibilityRole='button' onPress={onPressNext} disabled={disableButton}>
                <View style={{flexDirection: 'row', alignSelf: 'center', paddingLeft: 35, paddingRight: 30, paddingVertical: 13, borderRadius: global.settings.button_border_radius }}>
                  <H4 fontWeight='700' fontSize={18} color={isHovered ? Colors[16] : buttonTextColor}>{global.settings.text_to_upper_case == 0 ? buttonContentNext : buttonContentNext.toUpperCase()}</H4>
                  <View style={{ top: tablet ? 2 : 1, paddingLeft: 12 }}>
                    <FontAwesome5 name="chevron-right" style={{ position: 'absolute', margin: 0, top: '48%', transform: 'translateY(-50%)' }} size={ tablet ? 15 : 17} color={isHovered ? Colors[16] : colorArrow} />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>}
        </View>
      </View>
    </View>    
  );
}

// Style
const styler = StyleSheet.create({
  scrollView: { 
    backgroundColor: Colors[0],
    maxWidth: 1350,
    minHeight: "calc(100vh - 212px)",
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  rowView: { 
    flexDirection: 'row', 
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
  },
  widthView: {
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
    width: "100%",
   },
  objectView: {
    flexDirection: "row",
    width: "100%",
  },
  buttonArea: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonPrev: {
    marginRight: 20,
    alignSelf: 'flex-start',
    zIndex: 9999999,
  },
  buttonNext: {
    zIndex: 9999999,
  },
  hover: {
    cursor: 'not-allowed'
  },
  allowHover: {
    cursor: 'pointer'
  },
  rowViewMobile: {
    flexDirection: 'row', 
    paddingHorizontal: 30,
    flexWrap: 'wrap'
  },
  objectViewMobile: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    marginBottom: 15,
  },
  buttonAreaMobile: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 10,
    marginHorizontal: 10,
  },
  buttonPrevMobile: {
    width: "42%",
    alignSelf: 'flex-start',
    marginRight: 5,
    zIndex: 1,
    backgroundColor: '#f5f2f2',
  },
  buttonNextMobile: {
    width: "42%",
    marginLeft: 5,
    alignSelf: 'flex-end',
    zIndex: 1,
  },
  scrollViewMobile: { 
    backgroundColor: Colors[0], 
    marginTop: 10,
    paddingBottom: 60,
    minHeight: Dimensions.get('screen').height* 0.5 
  },
  inputViewMobile: {
    width: "100%",
    marginBottom: 15
  }
});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [DEVICE_SIZES.EXTRA_SMALL_DEVICE]: {
      buttonView: {
        position: 'fixed',
        paddingVertical: 10,
        right: 0,
        bottom: 60,
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderColor: '#999999', 
      },
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '100%',
      },
      colTwo: {
        width: '100%'
      },
      colOne: {
        width: '100%'
      },
    },
    [DEVICE_SIZES.MEDIUM_DEVICE]: {
      desktopButtons: {
        position: 'fixed', 
        bottom: 0, 
        zIndex: 999999999, 
        right: 0, 
        alignItems: 'flex-end', 
        width: '59%', 
        borderTopWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderColor: '#999999', 
        paddingRight: 43
      },
      colThree: {
        width: '50%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    },
    [DEVICE_SIZES.LARGE_DEVICE] : {
      desktopButtons: {
        position: 'fixed', 
        bottom: 0, 
        zIndex: 999999999, 
        backgroundColor: 'white', 
        right: 0, 
        alignItems: 'flex-end', 
        width: '65%',
        borderTopWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderColor: '#999999', 
        paddingRight: 43
      },
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '33%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    },
    [DEVICE_SIZES.EXTRA_LARGE_DEVICE] : {
      desktopButtons: {
        position: 'fixed', 
        bottom: 0, 
        zIndex: 999999999, 
        backgroundColor: 'white', 
        right: 0, 
        alignItems: 'flex-end', 
        width: '65%',
        borderTopWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderColor: '#999999', 
        paddingRight: 43
      },
      homeButton: {
        height: 45,
        width: 45,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'white',
      },
      colThree: {
        width: '33%',
      },
      colTwo: {
        width: '50%'
      },
      colOne: {
        width: '100%'
      },
    }
  }
)