// dit component is specifiek ontwikkeld voor de module KlimaatwijsOpVakantie
// als dit bij andere modules ook toegepast moete worden, is zowel aanpassing
// van het CMS als in deze module nodig


/// Libraries
import { APP_URL, API_URL, API_URL_TEST } from '@env';
import React, { useState, useEffect, useContext, useMemo, useCallback, useRef} from 'react';
import { View, Text, FlatList, TouchableOpacity, Image, RefreshControl, Dimensions, TextInput, StyleSheet, AccessibilityInfo, findNodeHandle, Platform, UIManager} from "react-native";
import * as WebBrowser from 'expo-web-browser';

// Styling 
import FncColors from '../styles/colors';
let Colors = [];
import { mobieleStyling as s } from '../styles/mobiel';

// Functions
import { adjustUri, textReplace, adjustWebTekst, readProperty, customConsoleLog, openUrlNewTab, sendGa } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { RESET_PARAM, SET_PARAM, SET_PARAM_CACHE } from '../context/actions/commonActions';

// Components
import Loader from '../components/loader';
import OuterContainer from '../components/outerContainer'
import MainContainer from '../components/mainContainer'
import TopContainer from '../components/topContainer'
import PostContainer from '../components/postContainer'
import BottomContainer from '../components/bottomContainer'
import HeaderFrame from '../components/headerFrame';
import Header from '../components/header';
import FooterFrame from '../components/footerFrame';
import Footer from '../components/footer';
import Harmonica from '../components/harmonica';
import { P, H1, H2, H3, H4, Space, PBold, SplitAlineas} from '../components/text';
import { TextBox, TextBoxMobile, ImageTextBoxMobile, ImageOnlyBox, ImageTextBox} from '../components/textBox';
import { Button, ButtonHome, ButtonHomeMobile} from '../components/button';
import IconSetter from './iconSetter';


//Variabele

var googleDone = false;

export default function Inspiratie( props ) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo.png');
  
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;

  //Props
  const module_naam = props.module || "onbekende_module";
  const disclaimer_titel = props.disclaimer_titel || "Disclaimer";
  const disclaimer_tekst = props.disclaimer_tekst || "Milieu Centraal gaat uiterst zorgvuldig te werk bij het ontwikkelen en actualiseren van deze adviestool. Desondanks kan Milieu Centraal geen aansprakelijkheid aanvaarden voor de gegevens en (soms snel) veranderende informatie.";
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const navigation = props.navigation;
  const minHeight = 660;
  const mobileHeight = 500;
  const title = props.title;
  const subtitle = props.subtitle
  const OverlayRef = useRef(null);

  const [footerOverlay, setFooterOverlay] = useState(false);
  const [footerOverlayTekst, setFooterOverlayTekst] = useState('');

  window.onpopstate = function(event) {
    //console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
    home();
  };

  const home = () => {
    commonDispatch({ type: RESET_PARAM });
    props.navigation.push('Main');
  }

  const navigateModule = () => {
    const param = { goto: "first", chain: [] };
    commonDispatch( { type: SET_PARAM, value: param } );
    navigation.push( "Main", {module: module_naam });
  }

  if(!googleDone){
    googleDone = true;
    sendGa(module_naam, 'open');
  }

  const show_overlay_tekst = ( tekst ) => {
    setFooterOverlay(true);
    setFooterOverlayTekst(tekst);
  }



  const refHandler = () => {
      if(OverlayRef && OverlayRef.current){
        const reactTag = findNodeHandle(OverlayRef.current);
        if(reactTag){
          OverlayRef.current.focus();
          setTimeout(() => {
            AccessibilityInfo.setAccessibilityFocus(reactTag);
          },300);
        }
      }
    };

  return (
    <View>
      { mobile == true ?
        <View style={{ backgroundColor: Colors[0], paddingBottom: 40 }}>
          <TextBoxMobile backgroundColor={Colors[11]} height={300}>
            <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={30} alignSelf={'left'} paddingHorizontal={20} paddingVertical={40}>{"Check de klimaatimpact van jouw vakantie"}</H1>
            <View style={{paddingBottom: 10, paddingTop: 15, paddingLeft: 15 }}>
              <ButtonHomeMobile height={55} color={Colors[0]} textColor={Colors[9]} caption={"Doe de check"} onPress={() => navigateModule()}/>
            </View>
          </TextBoxMobile>
          <TextBoxMobile backgroundColor={Colors[12]} height={300}>
            <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={30} alignSelf={'left'} paddingHorizontal={20} paddingVertical={40}>{"Duurzaam op vakantie met de trein. Hoe pak je dat aan?"}</H1>
            <View style={{ paddingBottom: 10, paddingTop: 15, paddingLeft: 15 }}>
              <ButtonHomeMobile height={55} color={Colors[0]} textColor={Colors[9]} caption={"Treinen in Europa"} onPress={() => openUrlNewTab('https://www.milieucentraal.nl/duurzaam-vervoer/duurzaam-op-vakantie/reizen-per-trein-door-europa/')}/>
            </View>
          </TextBoxMobile>
          <Text style={{marginVertical: 15}}/>
          <ImageTextBoxMobile height={500} backgroundColor={Colors[0]} imageSource={require('../assets/images/Bourtange.jpeg')}>
            <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={40} alignSelf={'left'} paddingHorizontal={20} paddingVertical={20}>{"Mooie plekken in eigen land"}</H1>
            <SplitAlineas color={Colors[9]} fontSize={21} textAlign={'left'} lineHeight={25} text={"In eigen land op vakantie gaan is pas echt klimaatwijs vakantievieren. En je ontdekt de mooiste plekjes! Wat dacht je van Bourtange? Een mooie vestingstadje dat sinds kort op de Werelderfgoedlijst staat."}/>
            <View style={{ paddingBottom: 30, paddingTop: 30, paddingLeft: 20 }}>
              <ButtonHomeMobile height={55} color={Colors[3]} textColor={Colors[9]} caption={"Meer inspiratie"} onPress={() => openUrlNewTab('https://www.27vakantiedagen.nl/mooiste-plekken-nederland/')}/>
            </View>
          </ImageTextBoxMobile>
          <Text style={{marginVertical: 5}}/>
          <ImageTextBoxMobile height={mobileHeight} backgroundColor={Colors[0]} imageSource={require('../assets/images/ScillyEilanden.jpeg')}>
            <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={40} alignSelf={'left'} paddingHorizontal={20} paddingVertical={20}>{"De mooiste plekken"}</H1>
            <SplitAlineas color={Colors[9]} fontSize={21} textAlign={'left'} lineHeight={25} text={"Voor de zon hoef je echt niet naar het andere eind van de wereld te vliegen. Voor de Engelse zuidkust ligt een eilandengroep met een tropisch klimaat: de Scilly eilanden. Te bereiken via trein en boot, dus geen vliegreis nodig."}/>
            <View style={{ paddingBottom: 30, paddingTop: 30, paddingLeft: 20 }}>
              <ButtonHomeMobile height={55} color={Colors[3]} textColor={Colors[9]} caption={"Meer vergelijkingen"} onPress={() => openUrlNewTab('https://www.natuurenmilieu.nl/blog/betere-bucketlist-bestemmingen/')}/>
            </View>
          </ImageTextBoxMobile>
          <Text style={{marginVertical: 5}}/>
          <ImageTextBoxMobile height={mobileHeight} backgroundColor={Colors[0]} imageSource={require('../assets/images/BerninaExpress.jpeg')}>
            <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={40} alignSelf={'left'} paddingHorizontal={20} paddingVertical={20}>{"Mooiste trips per trein"}</H1>
            <SplitAlineas color={Colors[9]} fontSize={21} textAlign={'left'} lineHeight={25} text={"Als je met de trein op vakantie gaat, begint de vakantiepret al zodra je in de trein stapt. De treinreis zelf is al een mooie beleving, leun achterover en geniet van het uitzicht. Je reist bovendien heel klimaatvriendelijk. Geniet van spectaculaire vergezichten vanuit de beroemde Zwitserse Bernina express."}/>
            <View style={{ paddingBottom: 30, paddingTop: 30, paddingLeft: 20 }}>
              <ButtonHomeMobile height={55} color={Colors[3]} textColor={Colors[9]} caption={"Meer over treinen"} onPress={() => openUrlNewTab('https://www.milieucentraal.nl/duurzaam-vervoer/duurzaam-op-vakantie/reis-per-trein-door-centraal-europa/')}/>
            </View>
          </ImageTextBoxMobile>
          { footerOverlay ?
            <View style={styles.footerOverlay}>
              <P>{ footerOverlayTekst }</P>
              <Button arrowIcon={false} color={Colors[3]} bottom={-5} square={true} fontSize={17} fontFamily={'Semibold'} textColor={Colors[9]} height={46} iconOrientation='none' caption={'Sluiten'} onPress={() => setFooterOverlay(false)} />
            </View>
          :
            null
          }
        </View>
        :
        <View style={{ paddingHorizontal: 19, paddingVertical: 20, maxWidth: 1440, marginRight: 'auto', marginLeft: 'auto' }}>
          <View style={{ flexDirection: 'row', paddingVertical: 20 }}>
            <TextBox tablet={tablet} backgroundColor={Colors[3]} height={Dimensions.get('window').height * 0.5} paddingTop={40}>
              <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={tablet ? 26 : 40} alignSelf={'left'} paddingHorizontal={20}>{"Check de klimaatimpact van jouw vakantie"}</H1>            
              <View style={{ left: tablet ? 20 : 30, paddingBottom: 30, position: 'absolute', bottom: 0 }}>
                <ButtonHome tablet={tablet} height={50} color={Colors[0]} textColor={Colors[9]} caption={"Doe de check"} onPress={() => navigateModule()}/>
              </View>
            </TextBox>
            <TextBox tablet={tablet} backgroundColor={Colors[12]} height={Dimensions.get('window').height * 0.5} paddingTop={40}>
              <H1 accessible={true} maxWidth='120%' color={Colors[9]} fontSize={tablet ? 26 : 40} alignSelf={'left'} paddingHorizontal={20}>{"Duurzaam op vakantie met de trein. Hoe pak je dat aan?"}</H1>
              <View accessibilityViewIsModal={true} style={ styles.viewButton }>
                <ButtonHome tablet={tablet} height={55} color={Colors[0]} textColor={Colors[9]} caption={"Treinen in Europa"} onPress={() => openUrlNewTab(' https://www.milieucentraal.nl/duurzaam-vervoer/duurzaam-op-vakantie/reizen-per-trein-door-europa/')}/>
              </View>
            </TextBox>
            <ImageOnlyBox description={'Gezin van vier op een zonnige dag op het strand hand in hand springen ze de lucht in je ziet hun schaduw op het zand'} tablet={tablet} height={Dimensions.get('window').height * 0.5} imageSource={require('../assets/images/milieucentraal/background.jpeg')}/>
          </View> 
          <View style={{ flexDirection: 'row', paddingVertical: 20 }}>
            <ImageTextBox 
              button={<View style= {styles.viewButton}>
                      <ButtonHome tablet={tablet} height={55} color={Colors[3]} textColor={Colors[9]} caption={"Meer inspiratie"} onPress={() => openUrlNewTab('https://www.27vakantiedagen.nl/mooiste-plekken-nederland/')}/>
                    </View>}
              tablet={tablet} height={tablet ? Dimensions.get('window').height * 0.36 : Dimensions.get('window').height * 0.9} backgroundColor={Colors[0]} imageSource={require('../assets/images/Bourtange.jpeg')}
              description={'Bourtange van bovenaf het is een soort stervorm met de gebouwen in het centrum van de ster en water om de complete stad daarbuiten is nog een stervorm van bomen.'} >
              <H1 accessible={true} maxWidth={tablet ? '160%' : '120%'} color={Colors[9]} fontSize={tablet ? 20 : 40} alignSelf={'left'} paddingHorizontal={tablet ? 15 : 20} paddingVertical={tablet ? 5 : 20}>{tablet ? "Mooie plekken in eigen land" : "De mooiste bestemmingen in eigen land"}</H1>
              <SplitAlineas color={Colors[9]} fontSize={tablet ? 14 : 16} textAlign={'left'} lineHeight={25} marginBottom={120} paddingHorizontal={tablet ? 15 : 20} text={"In eigen land op vakantie gaan is pas echt klimaatwijs vakantievieren. En je ontdekt de mooiste plekjes! Wat dacht je van Bourtange? Een mooie vestingstadje dat sinds kort op de Werelderfgoedlijst staat."}/>
            </ImageTextBox>
            <ImageTextBox 
              button={<View style={ styles.viewButton }>
                      <ButtonHome tablet={tablet} height={55} color={Colors[3]} textColor={Colors[9]} caption={"Ontdek de reisgids"} onPress={() => openUrlNewTab('https://natuurenmilieu.nl/themas/vervoer-reizen/duurzaam-reizen/tien-verrassende-droombestemmingen-dichtbij-reisgids/')}/>
                    </View>}
              tablet={tablet} height={tablet ? Dimensions.get('window').height * 0.36 : Dimensions.get('window').height * 0.9} backgroundColor={Colors[0]} imageSource={require('../assets/images/ScillyEilanden.jpeg')}
              description={'Een Tropisch strand bij de Scilly eilanden met een aantal boten op de achtergrond achter de boten kan je nog een eiland zien.'}>
              <H1 accessible={true} maxWidth={tablet ? '160%' : '120%'} color={Colors[9]} fontSize={tablet ? 20 : 40} alignSelf={'left'} paddingHorizontal={tablet ? 15 : 20} paddingVertical={tablet ? 5 : 20} hyphens={'auto'}>{ tablet ? "Droombestemmingen dichter bij huis" : "Droombestemmingen dichter bij huis"}</H1>
              <SplitAlineas color={Colors[9]} fontSize={tablet ? 14 : 16} textAlign={'left'} lineHeight={25} marginBottom={120} paddingHorizontal={tablet ? 15 : 20} text={"Voor een droombestemming hoef je niet in het vliegtuig te stappen. Europa biedt parels die een plek op je vakantiebucketlist verdienen én goed bereikbaar zijn met het openbaar vervoer of de (elektrische) auto. Kies bijvoorbeeld voor Scilly in plaats van Fiji!"}/>
            </ImageTextBox>
            <ImageTextBox 
              button={<View style={ styles.viewButton }>
                      <ButtonHome tablet={tablet} height={55} color={Colors[3]} textColor={Colors[9]} caption={"Meer over treinen"} onPress={() => openUrlNewTab('https://www.milieucentraal.nl/duurzaam-vervoer/duurzaam-op-vakantie/reis-per-trein-door-centraal-europa/')}/>
                    </View>}
              tablet={tablet} height={tablet ? Dimensions.get('window').height * 0.36 : Dimensions.get('window').height * 0.9} backgroundColor={Colors[0]} imageSource={require('../assets/images/BerninaExpress.jpeg')}
              description={'Een prachtige foto van de rooie Zwitserse Berina express die door het gebergte rijd met op de achtergrond hoge bergen met witte toppen.'}>
              <H1 accessible={true} maxWidth={tablet ? '160%' : '120%'} color={Colors[9]} fontSize={tablet ? 20 : 40} alignSelf={'left'} paddingHorizontal={tablet ? 15 : 20} paddingVertical={tablet ? 5 : 20}>{ tablet ? "Mooiste trips per trein" : "De mooiste trips per trein"}</H1>
              <SplitAlineas color={Colors[9]} fontSize={tablet ? 14 : 16} textAlign={'left'} lineHeight={25} marginBottom={120} paddingHorizontal={tablet ? 15 : 20} text={"Als je met de trein op vakantie gaat, begint de vakantiepret al zodra je in de trein stapt. De treinreis zelf is al een mooie beleving, leun achterover en geniet van het uitzicht. Je reist bovendien heel klimaatvriendelijk. Geniet van spectaculaire vergezichten vanuit de beroemde Zwitserse Bernina express."}/>
            </ImageTextBox>
          </View>
          { footerOverlay ?
            <View ref={OverlayRef} accessible={true} style={styles.footerOverlay}>
              <P>{ footerOverlayTekst }</P>
              <Button arrowIcon={false} color={Colors[7]} square={global.settings.border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[19] !== '' ? Colors[16] : Colors[9]} height={46} iconOrientation='none' caption={global.settings.text_to_upper_case == 0 ? 'Sluiten' : 'SLUITEN'} onPress={() => setFooterOverlay(false)} />
            </View>
          :
            null
          }
        </View>
      }
      <FooterFrame>
        <Footer
          navigation={props.navigation}
          logo={ {source: Logo } }
          title={title}
          subtitle={subtitle}
          mobile={mobile}
          disclaimer={() => [show_overlay_tekst(
            <View>
              <H2 fontFamily={'Semibold'} alignSelf={'left'}>{disclaimer_titel}</H2>
              <View><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: disclaimer_tekst }} /></View>
            </View>

          ), refHandler()]}
          cookies={() => [show_overlay_tekst(
            <View style={{ lineHeight: 30 }}>
              <H2 fontFamily={'Semibold'} alignSelf={'left'}>Cookies</H2>
              <P marginTop={10} marginBottom={15}>
                Dit advies-op-maat van Milieu Centraal maakt alleen gebruik van functionele cookies om de website goed te laten werken en analytische cookies om het aantal gebruikers anoniem te tellen. Je IP nummer blijft dus onbekend. Er wordt geen gebruik gemaakt van commerciële cookies.
              </P>
              
              <P fontFamily={'Semibold'}>Wat zijn cookies?</P>
              <P marginBottom={15}>
                Cookies zijn kleine tekstbestandjes die op je computer worden geplaatst door de website die je bekijkt, om je computer te kunnen herkennen tijdens je bezoek. Het belangrijkste doel van de cookie-wetgeving is bescherming van jouw privacy. De cookie-wetgeving is verwerkt in artikel 11.7a van de Telecommunicatiewet. Meer uitleg over cookies en privacy vind je op <a href="https://consuwijzer.nl" target="_blank">Consuwijzer.nl</a>.
              </P>

              <P fontFamily={'Semibold'}>Google Analytics cookies</P>
              <P>
                Milieu Centraal gebruikt voor de website milieucentraal.nl Google Analytics cookies. Dit is nodig om inzicht te krijgen in het gebruik van de website. Om dit zo privacy-vriendelijk mogelijk te maken heeft Milieu Centraal:
              </P>
              <ul>
                <li>een bewerkersovereenkomst met Google gesloten;</li>
                <li>het IP-adres anoniem gemaakt</li>
                <li>opties om gegevens te delen uitgezet</li>
                <li>geen andere Google-diensten in combinatie met deze Google Analytics-cookies.</li>
              </ul>
              <P>
                Wil je tegengaan dat cookies van Google Analytics geplaatst worden op jouw computer, download dan software van Google voor je browser (een zogeheten plug-in) die plaatsing van Google Analytics cookies voorkomt. Let op: deze instelling geldt dan voor alle websites die je bezoekt. Zie <a href="https://tools.google.com" target="_blank">https://tools.google.com</a>.
              </P>
              <P marginBottom={15}>
                Zie voor meer (Engelstalige) informatie over Google Analytics cookies: <a href="https://developers.google.com" target="_blank">https://developers.google.com.</a>
              </P>

              <P fontFamily={'Semibold'}>Cookies van sociale netwerken</P>
              <P marginBottom={15}>
                Milieu Centraal biedt de mogelijkheid om webpagina’s te delen via sociale netwerken. Die cookies zitten niet op de website van Milieu Centraal. Als je deze tool via social media gaat delen, krijg je te maken met de cookies en privacy-beleid van de gekozen social-media kanalen. 
              </P>

              <P fontFamily={'Semibold'}>Geen cookies voor advertenties, marketing of registratie persoonlijke gegevens</P>
              <P marginBottom={15}>
                Milieu Centraal gebruikt geen cookies waaruit persoonlijke informatie kan worden herleid, zoals een telefoonnummer of e-mailadres. Milieu Centraal gebruikt ook geen cookies voor e-mail- of marketingacties of cookies van adverteerders die je browse-activiteit bijhouden.
              </P>

              <P fontFamily={'Semibold'}>Cookies verwijderen of tegengaan?</P>
              <P marginBottom={15}>
                Wil je alle cookies verwijderen? De Consumentenbond legt uit hoe dat moet: <a href="https://consumentenbond.nl" target="_blank">www.consumentenbond.nl.</a>
              </P>
            </View>
          ), refHandler()]}
          toegankelijkheid={() => [show_overlay_tekst(
            <View>
              <H2 fontFamily={'Semibold'} alignSelf={'left'}>Toegankelijkheid</H2>
              <P lineHeight={30} marginTop={10} marginBottom={10}>Bij de bouw van milieucentraal.nl en adviestools besteedt Milieu Centraal veel aandacht aan toegankelijkheid en vindbaarheid. Doel is om alle informatie zo aan te bieden dat iedereen het kan bekijken en gebruiken.
              Neem voor vragen over de bouw en toegankelijkheid contact op met <a href="mailto:webredactie@milieucentraal.nl">webredactie@milieucentraal.nl</a></P>
            </View>
          ), refHandler()]}
        />
      </FooterFrame>
    </View>
  );
};

// Style
const styles = StyleSheet.create({
  viewButton: { 
    paddingBottom: 30, 
    position: 'absolute', 
    bottom: 0, 
    left: 30 
  },
  footerOverlay: {
    backgroundColor: 'rgba(255,255,255, 0.9)',
    flex: 1,
    zIndex: 99999,
    position: "absolute",
    maxWidth: 1140,
    width: "100%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: 30,
  },
});